import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";
import useScrollTo from "./useScrollTo";

const MIN_LANGTH = 30;
const MAX_LANGTH = 500;

const useFeedbackForm = () => {
	const isGuest = useIsGuest();
	const { data: appInitData } = useAppInitData();
	const { scrollToContent, content } = useScrollTo();
	const { isMobile } = useDevice();
	const verifyLoading = ref(false);
	const isLimitedValid = ref(true);
	const isFormSent = ref(false);

	const subject = ref("");
	const subjectSelectedId = ref(0);
	const isSubjectTouched = ref(false);
	const isSubjectValid = computed(() => subject.value !== "");
	const isSubjectShowError = computed(() => !isSubjectValid.value && isSubjectTouched.value);

	const feedbackMsg = ref("");
	const limit = ref(MIN_LANGTH);
	const isFeedbackMsgTouched = ref(false);
	const isFeedbackMsgValid = computed(
		() => validateStringLength(feedbackMsg.value, limit.value - 1) && feedbackMsg.value.replace(/\s/g, "").length
	);
	const isFeedbackMsgShowError = computed(() => !isFeedbackMsgValid.value && isFeedbackMsgTouched.value);

	const showEmailNotificationMessage = computed(
		() => !isGuest.value && appInitData.value?.primaryContact === "email" && !appInitData.value.emailConfirmed
	);

	const feedback = ref<HTMLDivElement>();
	const offset = computed(() => (showEmailNotificationMessage.value ? -170 : -70));

	const clearForm = () => {
		subject.value = "";
		feedbackMsg.value = "";
		isSubjectTouched.value = false;
		isFeedbackMsgTouched.value = false;
	};

	const showSuccessState = () => {
		setTimeout(() => {
			isFormSent.value = false;
		}, 5000);
	};

	const { data: dataCategories } = useLazyReqFetch({
		path: "/rest/player/feedback/category/categories/",
		method: "get",
		options: {
			cached: true,
			lazy: true,
			server: false
		}
	});

	const { data: dataSettings } = useLazyReqFetch({
		path: "/rest/player/feedback/category/settings/",
		method: "get",
		options: {
			cached: true,
			lazy: true,
			server: false
		}
	});

	const { execute: sendFeedbackForm } = useAsyncFetch({
		path: "/rest/player/feedback/category/",
		method: "post",
		fetchOptions: () => ({
			onRequest: () => {
				verifyLoading.value = true;
			},
			onResponse: ({ response }) => {
				verifyLoading.value = false;

				if (response._data.success) {
					if (feedback.value && isMobile) {
						window.scrollTo({
							top: window.scrollY + offset.value,
							behavior: "smooth"
						});
					}
					isFormSent.value = true;
					showSuccessState();
					return;
				}

				if (response._data.statusCode === 429) {
					isLimitedValid.value = false;
				}
			},
			onRequestError: () => {
				verifyLoading.value = false;
			},
			onResponseError: () => {
				verifyLoading.value = false;
			},
			body: {
				categoryId: subjectSelectedId.value,
				message: feedbackMsg.value
			}
		}),
		options: {
			immediate: false,
			lazy: true
		}
	});

	const categories = computed(() => dataCategories.value?.data);
	const minLength = computed(() => {
		const number = dataSettings.value?.data?.minLength || MIN_LANGTH;
		limit.value = dataSettings.value?.data?.minLength || MIN_LANGTH;
		return number;
	});
	const maxLength = computed(() => dataSettings.value?.data?.maxLength || MAX_LANGTH);
	const numberSymbols = computed(() => `${feedbackMsg.value.length} / ${maxLength.value}`);

	const handleSendFeedbackClick = async () => {
		if (!isSubjectValid.value) {
			isSubjectTouched.value = true;
			return;
		}

		subjectSelectedId.value = categories.value?.find((item) => item.name === subject.value)?.id || 0;

		await sendFeedbackForm();
		clearForm();
	};

	return {
		feedback,
		content,
		subject,
		offset,
		feedbackMsg,
		isSubjectTouched,
		showEmailNotificationMessage,
		isFeedbackMsgTouched,
		isFeedbackMsgValid,
		isSubjectValid,
		isLimitedValid,
		minLength,
		maxLength,
		verifyLoading,
		categories,
		handleSendFeedbackClick,
		scrollToContent,
		dataSettings,
		isSubjectShowError,
		isFeedbackMsgShowError,
		numberSymbols,
		isFormSent
	};
};

export default useFeedbackForm;
